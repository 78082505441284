<template>
  <div>
    <b-overlay :show="show" rounded="sm" no-fade>
      <b-card-code title="Informasi Data Notifikasi">
        <b-row class="mt-2 mb-2" align="end">
          <b-col md="3">
            <flat-pickr
              v-model="tanggal_filter"
              class="form-control"
              placeholder="Pilih Tanggal"
              :config="configs"
              @input="changeStatus()"
            />
          </b-col>

          <b-col md="3">
            <b-form-input
              id="name"
              v-model="title_filter"
              placeholder="Cari Notifikasi"
              @input="changeStatus()"
            />
          </b-col>

          <b-col md="3">
            <b-form-group label-for="basicInput">
              <b-form-select
                placeholder="Pilih Status"
                v-model="status_filter"
                @input="changeStatus()"
                :options="combo_status_filter"
              />
            </b-form-group>
          </b-col>

          <b-col md="2" align="start">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              @click="resetFilter()"
            >
              Reset
            </b-button>
          </b-col>

          <!-- <b-col align="end" md="6">
            <b-form-group label-for="basicInput">
              <b-form-select
                placeholder="Pilih Status"
                v-model="status_filter"
                @change="changeStatus()"
                :options="combo_status_filter"
              />
            </b-form-group>
          </b-col> -->
        </b-row>

        <!-- table -->
        <vue-good-table
          mode="remote"
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :search-options="{
            enabled: false,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: true,
          }"
          @on-sort-change="onSortChange"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'create_time'">
              {{ props.row.create_time.substring(0, 10) }} 
              {{ props.row.create_time.substring(11, 19) }}
            </span>

            <span v-else-if="props.column.field === 'status'">
              <span v-if="props.row.status === 'READ' ">
                <b-badge variant="success"> SUDAH DIBACA </b-badge>
              </span>

              <span v-if="props.row.status === 'UNREAD' ">
                <b-badge variant="warning"> BELUM DIBACA </b-badge>
              </span>
            </span>

            <span v-else-if="props.column.field === 'lineNumber'">
              {{ paging.page * 10 + (props.index + 1) }}.
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <!-- <div class="demo-inline-spacing"> -->
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  v-b-tooltip.hover.v-primary
                  title="Baca Notifikasi"
                  class="btn-icon"
                  @click="showModalDetail(props)"
                >
                  <feather-icon icon="SearchIcon" class="cursor-pointer" />
                </b-button>
              <!-- </div> -->
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="paging.size"
                  :options="paging.perPageOptions"
                  class="mx-1"
                  @input="handlePageChange"
                />
                <span class="text-nowrap">
                  of {{ paging.total_elements }} entries
                </span>
              </div>

              <div>
                <b-pagination
                  :value="paging.page + 1"
                  :total-rows="paging.total_elements"
                  :per-page="paging.size"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="handleChangePage"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>

        <!-- modal detail Form SIP-->
        <b-modal
          id="modal-detail-notifikasi"
          ref="ref-detail-notifikasi"
          title="Detail Notifikasi"
          hide-footer="true"
          size="lg"
          no-close-on-backdrop
        >

          <div class="row mt-2 mb-2">
            <div class="col-3">
              Tanggal
            </div>
            <div class="col">
              <!-- : {{ this.detail.create_time }} -->
              : {{  this.detail.create_time.substring(0, 10) }} 
              {{  this.detail.create_time.substring(11, 19) }}
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-3">
              Judul Notifikasi
            </div>
            <div class="col">
              : {{ this.detail.title }}
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-3">
              Status
            </div>
            <div class="col">
              <!-- : {{ this.detail.status }} -->

              <span v-if="this.detail.status === 'READ' "> :
                <b-badge variant="success"> SUDAH DIBACA </b-badge>
              </span>

              <span v-if="this.detail.status === 'UNREAD' "> :
                <b-badge variant="warning"> BELUM DIBACA </b-badge>
              </span>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-3">
              Pesan
            </div>
            <div class="col">
              : {{ this.detail.message }}
            </div>
          </div>

          <b-row class="mt-2"> 
            <b-col></b-col>
            <b-col></b-col>
            <b-col></b-col>
            <b-col align="end"
              ><b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                @click="kembaliModalNotif()"
              >
                Kembali
              </b-button>
            </b-col>
          </b-row>
        </b-modal>
        <!-- end modal detail form sip -->

      </b-card-code>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import axios from "@axios";
import vSelect from "vue-select";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BPagination,
  BFormFile,
  BOverlay,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BMedia,
  BAvatar,
  BMediaBody,
  BMediaAside,
  BAvatarGroup,
  BImg,
  BInputGroupPrepend,
  BCard,
  BCardText,
  BCardBody,
  BDropdownItem,
  BDropdown,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BCardCode,
    VBTooltip,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BPagination,
    VueGoodTable,
    BFormFile,
    BOverlay,
    vSelect,
    BFormSelect,
    BBadge,
    BFormTextarea,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
    BImg,
    BInputGroupPrepend,
    BCard,
    BCardBody,
    BCardText,
    BDropdownItem,
    BDropdown,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      //here
      file: null,

      log: [],
      dir: false,
      pages: ["5", "10", "20", "30"],

      paging: {
        page: 0,
        total_pages: 0,
        size: 10,
        total_elements: 0,
        perPageOptions: [10, 25, 50, 100],
      },

      columns: [
        {
          label: "No",
          field: "lineNumber",
          thClass: "text-center",
          tdClass: "text-center",
          width: "70px",
        },
        {
          label: "Tanggal",
          field: "create_time",
          thClass: "text-center",
          width: "200px",
        },
        {
          label: "Judul Notifikasi",
          field: "title",
          thClass: "text-center",
        },
        {
          label: "Status",
          field: "status",
          thClass: "text-center",
        },
        {
          label: "Aksi",
          field: "action",
          thClass: "text-center",
          tdClass: "text-center",
          width: "100px",
        },
      ],
      rows: [],
      // rows: [
      //   {
      //     "id": 1,
      //     "created_date": "2022-10-29T17:38:40.458188",
      //     "judul": "ini notifikasi"
      //   },
      //   {
      //     "id": 2,
      //     "created_date": "2022-10-29T17:38:40.458188",
      //     "judul": "ini notifikasi 2"
      //   },
      //   {
      //     "id": 3,
      //     "created_date": "2022-10-29T17:38:40.458188",
      //     "judul": "ini notifikasi 3"
      //   },
      //   {
      //     "id": 4,
      //     "created_date": "2022-10-29T17:38:40.458188",
      //     "judul": "ini notifikasi 4"
      //   }
      // ],

      tanggal_filter: "",
      title_filter: "",
      status_filter: "",

      configs: {
        mode: "range",
        allowInput: {
          allowInput: true,
          dateFormat: 'Y-m-d'
        },
      },

      combo_status_filter: [
        { value: "", text: "SEMUA" },
        { value: "READ", text: "SUDAH DIBACA" },
        { value: "UNREAD", text: "BELUM DIBACA" },
      ],

      detail: {
        create_time: "",
        message: "",
        status: "",
        title: "",
      },
      
      // old delete
      searchTerm: "",

      name: "",
      sort: "",

      create_time: "",

      show: false,
      aksi: "",
      id_sip: "",

      nama: "",
      nik: "",

      selected: null,

    };
  },

  beforeMount() {
    this.getData(this.paging.size, this.paging.page);
  },

  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true;
        return this.dir;
      }
      this.dir = false;
      return this.dir;
    },
  },

  created() {
    //here
  },

  methods: {
    getData(size, page) {
      this.show = true;

      let search0 = "";
      if (this.tanggal_filter) {
        search0 = "&date=" + this.tanggal_filter;
      } else {
        search0;
      }

      let search1 = "";
      if (this.title_filter) {
        search1 = "&title=" + this.title_filter;
      } else {
        search1;
      }

      let search2 = "";
      if (this.status_filter) {
        search2 = "&status=" + this.status_filter;
      } else {
        search2;
      }

      return new Promise(() => {
        axios
          .get(
            "v1/notification/all?" +
              "page=" +
              page +
              "&size=" +
              size +
              "&sort_by=create_time&sort_dir=-1" +
              search0 +
              search1 +
              search2
          )
          .then((res) => {
            this.rows = res.data.content;

            this.paging.page = res.data.page;
            this.paging.size = res.data.size;
            this.paging.total_elements = res.data.total_elements;
            this.paging.total_pages = res.data.total_pages;

            this.show = false;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
            }
            this.show = false;
          });
      });
    },

    showModalDetail(props) {
      this.ini_nama_sip = props.row.nama_sip;
      this.id_sip = props.row.id;

      // this.formUpdateSIP.nomor_str = props.row.nomor_str;
      // this.formUpdateSIP.penerbit_str = props.row.penerbit_str;

      this.$refs["ref-detail-notifikasi"].show();



      return new Promise(() => {
        axios
          .get(
            "v1/notification/" + props.row._id
          )
          .then((res) => {
            this.detail.create_time = res.data.create_time;
            this.detail.message = res.data.message;
            this.detail.status = res.data.status;
            this.detail.title = res.data.title;

            this.show = false;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
            }
            this.show = false;
          });
      });


    },

    kembaliModalNotif(){
      this.getData(this.paging.size, this.paging.page);
      this.$refs["ref-detail-notifikasi"].hide();
      this.show = false;
    },

    deleteInfo(props) {
      this.$swal({
        title: "Apakah Anda Yakin ?",
        text: "Data yang terhapus tidak dapat dikembalikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, Hapus!",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteApi(props);
        }
      });
    },

    deleteApi(props) {
      return new Promise(() => {
        axios
          .delete("v1/pendaftar/delete_sip?id_sip=" + props.row.id)
          .then((res) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Hapus Data Berhasil`,
                icon: "CheckCircleIcon",
                variant: "success",
                text: `Hapus Data SIP Berhasil`,
              },
            });
            this.getData(this.paging.size, this.paging.page);
          });
      });
    },

    changeStatus() {
      const page = (this.paging.page = 0);
      this.getData(this.paging.size, page);
    },

    resetFilter(){
      this.tanggal_filter = "";
      this.title_filter = "";
      this.status_filter = "";
      const page = (this.paging.page = 0);
      this.getData(this.paging.size, page);
    },

    handleSearch(searching) {
      const page = (this.paging.page = 0);
      this.getData(this.paging.size, page);
    },
    handleChangePage(value) {
      const page = value - 1;
      this.getData(this.paging.size, page);
    },
    handlePageChange(active) {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },
    onSortChange(params) {
      this.sort = `${params[0].field}`;
      this.getData(this.paging.size, this.paging.page);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
